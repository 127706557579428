import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will illustrate how to run open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` inside a Docker container.
As an example, you will send secure communications between a standalone webserver and curl.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to Bootstrap and run a private X.509 online Certificate Authority in a Docker container.`}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~4 mins, Lab time ~20 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know in <Link href="https://u.step.sm/discord" mdxType="Link">Discord</Link> or in <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#quickstart"
        }}>{`Quickstart`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation"
        }}>{`Manual installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-up-a-development-environment"
        }}>{`Setting Up a Development Environment`}</a></li>
    </ul>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open source -`}</strong>{` To interact with `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`, you'll want to install the `}<inlineCode parentName="li">{`step`}</inlineCode>{` client in your host environment. See our `}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli/installation"
        }}>{`installation docs`}</a>{`.`}</li>
    </ul>
    <h2>{`Quickstart`}</h2>
    <p>{`On your Docker host, run:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -d -v step:/home/step \\
    -p 9000:9000 \\
    -e "DOCKER_STEPCA_INIT_NAME=Smallstep" \\
    -e "DOCKER_STEPCA_INIT_DNS_NAMES=localhost,$(hostname -f)" \\
    smallstep/step-ca
`}</code></pre>
    <p>{`The following environment variables are available for CA configuration:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DOCKER_STEPCA_INIT_NAME`}</inlineCode>{` (`}<strong parentName="li">{`required`}</strong>{`) the name of your CA—this will be the issuer of your CA certificates`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DOCKER_STEPCA_INIT_DNS_NAMES`}</inlineCode>{` (`}<strong parentName="li">{`required`}</strong>{`) the hostname(s) or IPs that the CA will accept requests on`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DOCKER_STEPCA_INIT_PROVISIONER_NAME`}</inlineCode>{` a label for the initial admin (JWK) provisioner. Default: "admin"`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DOCKER_STEPCA_INIT_SSH`}</inlineCode>{` set this to a non-empty value to create an SSH CA`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DOCKER_STEPCA_INIT_PASSWORD`}</inlineCode>{`  specify a password for the encrypted CA keys and the default CA provisioner. A password is generated by default. Note: In a production environment, a more secure option for specifying a password is to use the manual installation process, below.`}</li>
    </ul>
    <p>{`Once `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` is running, the CA's URL and SHA256 fingerprint are all clients need to bootstrap with the CA.`}</p>
    <p>{`Let's bootstrap the `}<inlineCode parentName="p">{`step`}</inlineCode>{` client. Run:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  CA_FINGERPRINT=$(docker run -v step:/home/step smallstep/step-ca step certificate fingerprint certs/root_ca.crt)
  step ca bootstrap --ca-url https://localhost:9000 --fingerprint $CA_FINGERPRINT
}
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`The root certificate has been saved in /Users/alice/.step/certs/root_ca.crt.
Your configuration has been saved in /Users/alice/.step/config/defaults.json.
`}</code></pre>
    <p>{`Your CA is ready for use. You can view your CA password via:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -v step:/home/step smallstep/step-ca cat secrets/password
`}</code></pre>
    <h2>{`Manual installation`}</h2>
    <h3>{`1. Pull down the Docker image`}</h3>
    <p>{`Get the latest version of `}<inlineCode parentName="p">{`step-ca`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`docker pull smallstep/step-ca
`}</code></pre>
    <h3>{`2. Bring up PKI bootstrapping container`}</h3>
    <p>{`The Docker volume `}<inlineCode parentName="p">{`step`}</inlineCode>{` will hold your CA configuration, keys, and database.`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -it -v step:/home/step smallstep/step-ca step ca init
`}</code></pre>
    <p>{`The init command will step you through the bootstrapping process. Example output:`}</p>
    <pre><code parentName="pre" {...{}}>{`✔ What would you like to name your new PKI? (e.g. Smallstep): Smallstep
✔ What DNS names or IP addresses would you like to add to your new CA? (e.g. ca.smallstep.com[,1.1.1.1,etc.]): localhost
✔ What address will your new CA listen at? (e.g. :443): :9000
✔ What would you like to name the first provisioner for your new CA? (e.g. you@smallstep.com): admin@smallstep.com
✔ What do you want your password to be? [leave empty and we'll generate one]:

Generating root certificate...
all done!

Generating intermediate certificate...
all done!

✔ Root certificate: /home/step/certs/root_ca.crt
✔ Root private key: /home/step/secrets/root_ca_key
✔ Root fingerprint: 86a278f34e58c7ab04313aff0e8e5114f1d1da955ecb20412b3d32cc2267ddcd
✔ Intermediate certificate: /home/step/certs/intermediate_ca.crt
✔ Intermediate private key: /home/step/secrets/intermediate_ca_key
✔ Database folder: /home/step/db
✔ Default configuration: /home/step/config/defaults.json
✔ Certificate Authority configuration: /home/step/config/ca.json

Your PKI is ready to go. To generate certificates for individual services see 'step help ca'.
`}</code></pre>
    <p><strong parentName="p">{`Save the root fingerprint value`}</strong>{`! You'll need it for client bootstrapping.`}</p>
    <h3>{`3. Place the PKI root password in a known safe location.`}</h3>
    <p>{`The image is expecting the password to be placed in `}<inlineCode parentName="p">{`/home/step/secrets/password`}</inlineCode>{`. Bring up the shell prompt in the container again and write that file:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -it -v step:/home/step smallstep/step-ca sh
`}</code></pre>
    <p><strong parentName="p">{`Inside your container`}</strong>{`, write the file into the expected location:`}</p>
    <pre><code parentName="pre" {...{}}>{` echo "<your password here>" > /home/step/secrets/password
`}</code></pre>
    <p>{`Your CA is configured and ready to run.`}</p>
    <h3>{`4. Start `}<inlineCode parentName="h3">{`step-ca`}</inlineCode></h3>
    <p>{`The CA runs an HTTPS API on port 9000 inside the container. Expose the server address locally and run the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` with:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -d -p 9000:9000 -v step:/home/step smallstep/step-ca
`}</code></pre>
    <p>{`Now, on your Docker host, bootstrap your `}<inlineCode parentName="p">{`step`}</inlineCode>{` client configuration:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  CA_FINGERPRINT=$(docker run  -v step:/home/step smallstep/step-ca step certificate fingerprint /home/step/certs/root_ca.crt)
  step ca bootstrap --ca-url https://localhost:9000 --fingerprint $CA_FINGERPRINT
}
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`The root certificate has been saved in /Users/alice/.step/certs/root_ca.crt.
Your configuration has been saved in /Users/alice/.step/config/defaults.json.
`}</code></pre>
    <p>{`Your local`}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI is now configured to use the container instance of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and our new root certificate is trusted by our local environment (inserted into local trust store).`}</p>
    <p>{`Run a health check:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl https://localhost:9000/health
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`{"status":"ok"}
`}</code></pre>
    <h3>{`Next steps:`}</h3>
    <ul>
      <li parentName="ul">{`See our `}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-ca/basic-certificate-authority-operations"
        }}>{`Basic CA Operations`}</a>{` guide.`}</li>
      <li parentName="ul">{`See our `}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-ca/configuration"
        }}>{`Configuration Guide`}</a>{` to learn more about tailoring `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to your infrastructure.`}</li>
      <li parentName="ul">{`Or, set up a development environment in the next section.`}</li>
    </ul>
    <h2>{`Setting Up a Development Environment`}</h2>
    <p><strong parentName="p">{`You will need:`}</strong></p>
    <ul>
      <li parentName="ul">{`A Python 2.7.x interpreter to bring up a standalone webserver (optional)`}</li>
    </ul>
    <p>{`Run this section on your host machine where Docker is installed.`}</p>
    <p>{`Once you've bootstrapped your local environment, you can now run web services configured with TLS and mTLS. First, get a certificate for `}<inlineCode parentName="p">{`localhost`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`step ca certificate localhost localhost.crt localhost.key
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`✔ Key ID: aTPGWP0qbuQdflR5VxtNouDIOXyNMH1H9KAZKP-UcHo (admin)
✔ Please enter the password to decrypt the provisioner key:
✔ CA: <https://localhost:9000/1.0/sign>
✔ Certificate: localhost.crt
✔ Private Key: localhost.key
`}</code></pre>
    <p>{`Now save a copy of your root CA certificate:`}</p>
    <pre><code parentName="pre" {...{}}>{`step ca root root_ca.crt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`The root certificate has been saved in root_ca.crt.
`}</code></pre>
    <p>{`Next, let's launch a web server secured by HTTPS:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
cat <<EOF > server.py
import BaseHTTPServer, ssl

class HelloHandler(BaseHTTPServer.BaseHTTPRequestHandler):
    def do_GET(self):
        self.send_response(200);
        self.send_header('content-type', 'text/html; charset=utf-8');
        self.end_headers()
        self.wfile.write(b'\\\\n\\\\xf0\\\\x9f\\\\x91\\\\x8b Hello! Welcome to TLS \\\\xf0\\\\x9f\\\\x94\\\\x92\\\\xe2\\\\x9c\\\\x85\\\\n\\\\n')

httpd = BaseHTTPServer.HTTPServer(('', 8443), HelloHandler)
httpd.socket = ssl.wrap_socket(httpd.socket,
                   server_side=True,
                   keyfile="localhost.key",
                   certfile="localhost.crt",
                   ca_certs="root_ca.crt")
httpd.serve_forever()
EOF

python server.py
}
`}</code></pre>
    <p>{`Open up another terminal to see your server running:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ curl https://localhost:8443
👋 Hello! Welcome to TLS 🔒✅

`}</code></pre>
    <p>{`Or visit `}<a parentName="p" {...{
        "href": "https://localhost:8443/"
      }}>{`https://localhost:8443`}</a>{` from your browser.`}</p>
    <h3>{`Further Reading`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`[step`}</inlineCode>{` Documentation](`}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli"
        }}>{`https://smallstep.com/docs/step-cli`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli/basic-crypto-operations"
        }}>{`Getting Started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/smallstep/cli"
        }}>{`GitHub Repository`}</a></li>
    </ul>
    <h3>{`Troubleshooting`}</h3>
    <h3>{`Raspberry Pi Badger database errors`}</h3>
    <p>{`When you run `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` on a Raspberry Pi, you might get the following error in
your container logs:`}</p>
    <pre><code parentName="pre" {...{}}>{`step-ca  | badger 2021/05/08 20:13:12 INFO: All 0 tables opened in 0s
step-ca  | Error opening database of Type badger with source /home/step/db: error opening Badger database: Mmap value log file. Path=/home/step/db/000000.vlog. Error=cannot allocate memory

`}</code></pre>
    <p>{`To fix it, edit the `}<inlineCode parentName="p">{`db`}</inlineCode>{` configuration block in the file `}<inlineCode parentName="p">{`config/ca.json`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -v step:/home/step -it smallstep/step-ca vi /home/step/config/ca.json
`}</code></pre>
    <p>{`Change the value of `}<inlineCode parentName="p">{`badgerFileLoadingMode`}</inlineCode>{` from `}<inlineCode parentName="p">{`""`}</inlineCode>{` to `}<inlineCode parentName="p">{`"FileIO"`}</inlineCode>{`.`}</p>
    <p>{`You will end up with this:`}</p>
    <pre><code parentName="pre" {...{}}>{`    "db": {
          "type": "badger",
          "dataSource": "/home/step/db",
          "badgerFileLoadingMode": "FileIO"
    },
`}</code></pre>
    <p>{`Save and restart the container.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      